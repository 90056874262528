<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { useNotificationsStore } from '@/store/notifications'
import IconLogo from '@/assets/images/logo-white.svg'

const authStore = useAuthStore()
const { isLoggedIn, isLoginProcessing } = storeToRefs(authStore)
const customerStore = useCustomerStore()
const { isCreator } = storeToRefs(customerStore)
const { isPublished } = useCreatorStore()

const notificationsStore = useNotificationsStore()
const header = ref<HTMLElement | null>(null)

const route = useRoute()

defineProps<{
  isBecomeCreatorLp: boolean
}>()

useIntersectionObserver(header, ([{ isIntersecting }]) => {
  notificationsStore.headerIsVisible = isIntersecting
})
</script>

<template>
  <header
    ref="header"
    class="app-header-light z-50 bg-darken-half"
  >
    <div
      class="container relative flex h-20 w-full items-center justify-between"
    >
      <NuxtLink
        to="/"
        aria-label="Go to Homepage"
        class="brightness-2200"
      >
        <IconLogo
          class="w-[102px]"
          aria-hidden="true"
        />
      </NuxtLink>
      <nav
        aria-label="Main navigation"
        class="flex items-center"
      >
        <HeaderMenuGuest
          v-if="
            !isLoggedIn ||
            isLoginProcessing ||
            (isCreator && isPublished && route.name === 'home-page')
          "
          :is-become-creator-lp="isBecomeCreatorLp"
        />
        <HeaderMenuUser
          v-else
          :email="customerStore.email"
          :name-letter="customerStore.nameLetter"
        />
      </nav>
    </div>
  </header>
</template>

<style scoped>
.app-header-light :deep(.p-button:not(.dropdown-trigger)) {
  @apply md:bg-transparent md:brightness-2200;
}

.app-header-light :deep(a) {
  @apply md:bg-transparent md:brightness-2200;
}

.app-header-light :deep(.guest-menu-trigger) {
  @apply bg-transparent brightness-2200;
}
:deep(.dropdown-trigger:not(:focus-visible):not(:hover) .pi-bars) {
  @apply text-white;
}
:deep(.dropdown-trigger:focus-visible) {
  @apply outline-white;
}
</style>
