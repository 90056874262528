<script setup lang="ts">
import { storeToRefs } from 'pinia'

const route = useRoute()
const pageName = ref(route.name)
const { isLoggedIn, isLoginProcessing } = storeToRefs(useAuthStore())

watch(
  () => route.name,
  () => {
    pageName.value = route.name
  },
)

const { isCreator } = storeToRefs(useCustomerStore())

// TODO Fix isLoginProcessing which refreshes popup on marketplace during login
const isLight = computed(
  () =>
    (!isLoggedIn.value && pageName.value === 'home-page') ||
    pageName.value === 'become-a-creator-page' ||
    isLoginProcessing.value ||
    (isCreator.value && pageName.value === 'home-page'),
)
const isFooterSticky = computed(() => {
  return pageName.value === 'collabs-search-page'
})
</script>

<template>
  <div class="flex h-screen flex-col">
    <AppHeaderLight
      v-if="isLight"
      :is-become-creator-lp="pageName === 'become-a-creator-page'"
    />
    <AppHeader v-else />
    <main
      :class="[
        {
          container:
            pageName !== 'become-a-creator-page' &&
            pageName !== 'home-page' &&
            !(pageName as string)?.includes('create-collab'),
        },
        'mb-auto flex flex-1 flex-col',
      ]"
    >
      <AppNotifications />
      <slot />
    </main>
    <AppFooter :is-sticky="isFooterSticky" />
  </div>
</template>
