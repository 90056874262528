<script setup lang="ts">
import IconExclamationTriangle from '@/assets/images/exclamation-triangle.svg'
import IconCheckCircle from '@/assets/images/check-circle.svg'

interface NotificationProps {
  id: number
  severity?: 'success' | 'error'
  text: string
  closable?: boolean
  sticky?: boolean
  timeout?: number
}
const props = withDefaults(defineProps<NotificationProps>(), {
  severity: 'success',
  closable: true,
  sticky: false,
  timeout: 4000,
})
const store = useNotificationsStore()
let timeoutId: ReturnType<typeof setTimeout>
const close = () => {
  timeoutId && clearTimeout(timeoutId)
  store.remove(props.id)
}

onMounted(() => {
  if (!props.sticky) {
    timeoutId = setTimeout(() => {
      store.remove(props.id)
    }, props.timeout)
  }
})
</script>

<template>
  <div
    class="flex"
    role="status"
    aria-live="assertive"
  >
    <div
      class="ml-auto flex w-full items-center gap-4 rounded border-[1.5px] px-4 py-2 text-base text-blue-100 sm:py-3.5"
      :class="{
        'border-green-200 bg-green-400 text-green-100': severity === 'success',
        'border-pink-100 bg-pink-200 text-red-100': severity === 'error',
      }"
    >
      <div>
        <IconCheckCircle
          v-if="severity === 'success'"
          class="h-8 w-8 fill-green-300"
        />
        <IconExclamationTriangle v-else />
      </div>
      <span class="sr-only">{{ severity }}</span>
      <span class="max-w-content notification-text break-words">
        {{ text }}
      </span>
      <button
        v-if="closable"
        class="p-link ml-auto flex items-center"
        :class="{
          'text-green-300': severity === 'success',
          'text-pink-100': severity === 'error',
        }"
        aria-label="Close notification"
        type="button"
        @click="close"
      >
        <i class="pi pi-times" />
      </button>
    </div>
  </div>
</template>

<style scoped>
.notification-text {
  word-break: break-word;
}
</style>
