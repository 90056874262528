<script setup lang="ts">
const store = useNotificationsStore()
</script>

<template>
  <div
    class="container fixed right-1/2 z-[1102] mt-2 translate-x-1/2 sm:right-0 sm:max-w-sm sm:translate-x-0 xl:right-1/2 xl:-mx-[368px] xl:translate-x-1/2"
    :class="{
      'top-0': !store.headerIsVisible,
    }"
  >
    <div class="grid grid-cols-1 gap-2">
      <TransitionGroup name="notifications">
        <BaseNotification
          v-for="notification in store.notifications"
          :key="notification.id"
          v-bind="notification"
        />
      </TransitionGroup>
    </div>
  </div>
</template>

<style scoped>
.notifications-move,
.notifications-enter-active,
.notifications-leave-active {
  @apply transition duration-200 ease-in-out;
}

.notifications-enter-active {
  @apply duration-200;
}

.notifications-move,
.notifications-leave-active {
  @apply duration-1000;
}

.notifications-enter-from,
.notifications-leave-to {
  @apply opacity-0;
}

.notifications-leave-to {
  @apply translate-x-96;
}

.notifications-leave-active {
  @apply absolute w-full;
}
</style>
